.legend {
    align-items: center;
    left: 0 !important;
    width: 100% !important;
}

.slide div {
    &:after{
        content:'';
        position: absolute;
        border: 10px solid var(--secondary-color);
        right: 85px;
        left: 85px;
        bottom: 85px;
        top: 85px;
        z-index: -1;
        border-radius: 0 2.1429rem;
    }
}

.carousel-status {
    display: none;
}
a {
    text-decoration: none;
    color: #32455B
}