@import url('https://fonts.cdnfonts.com/css/ethnocentric');
@font-face {
  font-family: 'KABELD';
  src: url('assets/fonts/KABELD.TTF') format('truetype');
}  

/* Global Variables */
:root {
  --primary-color:#32455B;
  --secondary-color:#90b4df;
  --primary-nav-color:#1f4674;
  --primary-bg:rgb(50 69 91 / 14%);
  --font-color:#b7d6f8;
  --bg-color:#23262A;
  --bg-dark-color:#1A2228;
  --white-color:#fdfffc;
  --green-color:#0abf53;
  --green-color-bg:rgb(10 191 83 / 17%);
  --blue-color:#6168ff;
  --blue-color-bg:rgb(97 104 255 / 9%);
  --red-color:#990033;
  --red-color-bg:rgb(153 0 51 / 9%);
  --border-color-light:rgb(255,255,255, 0.2);
  --box-shadow: 0 1rem, 3rem rgb(0 0 0 / 12%);
  --dark-layer-color:linear-gradient(to top, rgb(35 38 42 /67%), rgb(37 40 44 / 17%));

  --primary-font: 'Ethnocentric Rg', sans-serif;
  --secondary-font: 'KABELD';
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  font-family: var(--secondary-font);
  font-size: 16px;
  background-color: var(--bg-color);
}

::-webkit-scrollbar{
  width: .875rem;
}
::-webkit-scrollbar-track{
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb{
  background: var(--secondary-color);
}

a{
  text-decoration: none;
}

.container-fluid{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
.container{
  width: auto;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.btn_wrapper{
  margin-top: 5rem;

  @media (max-width: 992px){
    width: 100%;
    text-align: center;
    display: block;
  }
  @media (max-width: 767px){
    margin-bottom: 3rem;
  }

  .btn{
    padding: .857rem 2.571rem;
    color: var(--secondary-color);
    font-size: 1.143rem;
    border-radius: 2.143rem;
    font-weight: 600;
    box-shadow: 0 .5rem 1rem rgb(16 16 16);
    outline: 2px solid var(--primary-color);
    outline-offset: 0px;
    border: none;
    cursor: pointer;
    background: var(--primary-color);
    transition: all .3s ease-in-out;
    &:hover{
      outline-offset: 10px;
    }
  }
}

h1{
  font-family: var(--primary-font);
  font-size: 3.875rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  color:var(--white-color)
}
h2{
  font-family: var(--primary-font);
  font-size: 2.875rem;
  font-weight: 600;
  color:var(--white-color)
}
h3{
  font-size: 2.25rem;
  font-weight: 600;
  color:var(--white-color)
}
h4{
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 600;
  color:var(--white-color)
}
h5{
  font-size: 1.5rem;
  line-height: 1.2rem;
  margin-bottom: .9375rem;
  font-weight: 500;
  color:var(--white-color)
}
h6{
  font-size: .875rem;
  margin-bottom: .9375rem;
  text-transform: uppercase;
  font-weight: 300;
  color:var(--white-color)
}
p{
  font-size: 1rem;
  color: var(--font-color);
  line-height: 1.625rem
}