#site_header{
    font-family: var(--primary-font);
    position: relative;
    z-index: 5;

    &.sticky{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        z-index: 100;
        background-color: var(--bg-color);
        padding: 0.5rem 0 1rem;
        transform: translateY(0px);
        transition: all 0.3s ease-in-out;
        animation:navbar 0.3s linear forwards;
    }

    background-color: var(--bg-color);
    padding: 0.5rem 0 1rem;
    transform: translateY(0px);
    transition: 0.3s all ease-in-out;

    .navbar{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar_brand img{
            max-width: 80%;
        }

        .navbar_toggler{
            display: none;

            & svg{
                line-height: 2.5rem;
                margin-right: 1rem;
                text-align: center;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;

                & path{
                    fill:var(--primary-color)
                }
            }

            @media (max-width: 992px){
                display: block;
            }
        }
        .menu_items{
            @media (max-width: 992px){
                position: absolute;
                top: 100;
                right: 0;
                bottom: 0;
                background: var(--bg-color);
                z-index: 9999;
                transform: translateY(-250px);
                transition: all 0.3s ease-in-out;

                &.active{
                    max-width: 250px;
                    min-width: 250px;
                    top: 3.75rem;
                    left: auto;
                    right: 0;
                    margin-right: 0;
                    text-align: right;
                    padding: 0rem;
                    box-sizing: border-box;
                    transform: translateX(0px);
                }
            }

            & ul{
                margin: 0;
                padding: 0;

                & li{
                    list-style:none;
                    display: inline-block;
                    position: relative;
                    padding: 1rem;

                    @media (max-width: 992px){
                        display: block;
                        background-color: var(--bg-color);
                        border-bottom: 1px solid rgba(112, 125, 139, 0.8);
                    }

                    & a{
                        padding: 1rem 0;
                        font-size: .97rem;
                        font-weight: 500;
                        color: var(--primary-color);
                        cursor: pointer;

                        @media (max-width: 992px){
                            padding: 1rem 0;
                            display: block;
                        }

                        &.active,
                        &:focus
                        {
                            color: var(--secondary-color);
                        }
                        &:hover{
                            color: var(--primary-nav-color);
                        }
                    }
                }
            }
        }

        .menu_items ul li a.active, 
        .menu_items ul li a:focus{
            color: var(--secondary-color);
        }
        .menu_items ul li a:hover{
            color: var(--primary-nav-color);
        }
    }
}

@keyframes navbar{
    0%, 100%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-80px);
    }
}